<template>
  <!-- <el-tabs v-model="activeName" tab-position="left" style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="客户" name="first">
      <customer ref="customer" :tabName="tabName" ></customer>
    </el-tab-pane>
    <el-tab-pane label="供应商" name="second">
      <supplier ref="supplier" :tabName="tabName" ></supplier>
    </el-tab-pane>
    <el-tab-pane label="银行账户" name="third">
      <bankAccount ref="bankAccount" :tabName="tabName" ></bankAccount>
    </el-tab-pane>
  </el-tabs> -->
  <!-- <div :style="{ height: contentStyleObj }"> -->
    <qzf-tabs v-model:value="value" @tab-click="handleClick">
      <qzf-tabs-item label="客户" name="1" :cache="false">
        <customer ref="customer" ></customer>
      </qzf-tabs-item>
      <qzf-tabs-item label="供应商" name="2" :cache="false">
        <supplier ref="supplier" ></supplier>
      </qzf-tabs-item>
      <qzf-tabs-item label="银行账户" name="3" :cache="false">
        <bankAccount ref="bankAccount" ></bankAccount>
      </qzf-tabs-item>
      <qzf-tabs-item label="进项" name="5" :cache="false">
        <invoice-in ref="bankAccount" ></invoice-in>
      </qzf-tabs-item>
      <qzf-tabs-item label="销项" name="6" :cache="false">
        <invoice-out ref="bankAccount" ></invoice-out>
      </qzf-tabs-item>
      <qzf-tabs-item label="银行" name="4" :cache="false">
        <bank ref="bankAccount" ></bank>
      </qzf-tabs-item>
    </qzf-tabs>
  <!-- </div> -->
</template>

<script>
import customer  from "./components/customer.vue"
import supplier  from "./components/supplier.vue"
import bankAccount from "./components/bankAccount.vue"
import bank from "./components/bank.vue"
import invoiceIn from "./components/invoiceIn.vue"
import invoiceOut from "./components/invoiceOut.vue"
export default {
  name:'business',
  components:{
    customer,
    supplier,
    bankAccount,
    bank,
    invoiceIn,
    invoiceOut
  },
  data() {
    return {
      value: '1',
      contentStyleObj: {}, //高度变化
    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(190);
  },
  methods:{
    // handleClick(tab, event) {
    //   if (tab == "1") {
    //     this.$refs.customer.getList()
    //   } else if (tab == "2") {
    //     this.$refs.supplier.getList()
    //   }else if (tab == "3") {
    //     this.$refs.bankAccount.getList()
    //   }
    // },
  }

}
</script>

<style>

</style>