import request from '@/utils/request'


//银行列表
export function bankMemoryList(data) {
  return request({
    url: 'api/v2/memory/bankMemoryList',
    method: 'post',
    data
  })
}
//银行删除
export function delBankMemory(data) {
  return request({
    url: 'api/v2/memory/delBankMemory',
    method: 'post',
    data
  })
}
//进项列表
export function incomeMemoryList(data) {
  return request({
    url: 'api/v2/memory/incomeMemoryList',
    method: 'post',
    data
  })
}
//销项列表
export function outPutMemoryList(data) {
  return request({
    url: 'api/v2/memory/outPutMemoryList',
    method: 'post',
    data
  })
}

//进项删除
export function delIncomeMemory(data) {
  return request({
    url: 'api/v2/memory/delIncomeMemory',
    method: 'post',
    data
  })
}

//销项删除
export function delOutPutMemory(data) {
  return request({
    url: 'api/v2/memory/delOutPutMemory',
    method: 'post',
    data
  })
}

//进项销方记忆
export function incomeCorpMemoryList(data) {
  return request({
    url: 'api/v2/memory/incomeCorpMemoryList',
    method: 'post',
    data
  })
}

//销项购方记忆
export function outPutCorpMemoryList(data) {
  return request({
    url: 'api/v2/memory/outPutCorpMemoryList',
    method: 'post',
    data
  })
}

//删除进项销方记忆
export function delIncomeCorpMemory(data) {
  return request({
    url: 'api/v2/memory/delIncomeCorpMemory',
    method: 'post',
    data
  })
}

//删除销项购方记忆
export function delOutPutCorpMemory(data) {
  return request({
    url: 'api/v2/memory/delOutPutCorpMemory',
    method: 'post',
    data
  })
}