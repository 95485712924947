<template>
  <div class="app-container">
    <div class="top_btns">
      <div class="left_box">
        <el-input
          placeholder="请输入销方名称"
          v-model="listQuery.corpName"
          style="width: 160px"
          class="filter-item"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" size="small" @click="getList">
          <el-icon><Search /></el-icon> <span> 搜索</span>
        </el-button>
      </div>
      <div class="right_box">
        <qzf-button
          button_code="yhzh_sc"
          plain
          size="small"
          type="primary"
          @success="corpMemory"
        >
          <el-icon><SetUp /></el-icon> <span>销方记忆</span>
        </qzf-button>
        <qzf-button
          button_code="yhzh_sc"
          plain
          size="small"
          type="danger"
          @success="delAll"
        >
          <el-icon><Delete /></el-icon> <span>删除</span>
        </qzf-button>
      </div>
    </div>
    <el-table
      stripe
      :data="list"
      v-loading="loading"
      :height="contentStyleObj"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column
        align="left"
        prop="corpName"
        label="销方名称"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="subjectName"
        label="业务类型"
        min-width="180"
      >
        <template #default="scope">
          <business-type
            :borderNone="true"
            :disabledIn="true"
            v-model:businessId="scope.row.ywlxId"
            v-model:fzhsId="scope.row.fzhsID"
            v-model:itemId="scope.row.itemId"
            v-model:spec="scope.row.spec"
            v-model:itemType="scope.row.itemType"
            type="in"
            v-model:assetsName="scope.row.name"
          ></business-type>
        </template>
      </el-table-column>
      <el-table-column
        align="left"
        prop="name"
        label="开票项目"
        min-width="180"
      >
        <template #default="scope">
          {{ scope.row.name
          }}{{ scope.row.remark ? "(" + scope.row.remark + ")" : "" }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="itemName"
        label="补充项目"
        min-width="180"
      >
        <template #default="scope">
          <business-type
            v-model:businessId="scope.row.ywlxId"
            v-model:itemId="scope.row.itemId"
            :disabledIn="true"
            v-model:fzhsId="scope.row.fzhsID"
            v-model:itemType="scope.row.itemType"
            :name="scope.row.name"
            :spec="scope.row.spec"
            :unit="scope.row.unit"
            type="in"
            v-model:assetsName="scope.row.name"
            v-model:assetsCount="scope.row.count"
            v-model:assetsAmount="scope.row.amount"
            v-model:assetsDate="scope.row.createdAt"
          ></business-type>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="address"
        label="操作"
        min-width="130"
      >
        <template #default="scope">
          <qzf-button
            button_code="yhzh_sc"
            size="small"
            @success="del(scope.row)"
            link
            >删除</qzf-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
  <el-drawer
    title="销方记忆"
    v-model="drawer"
    :direction="direction"
    destroy-on-close
    size="900px"
  >
    <div class="top_btns">
      <div class="left_box">
        <el-input
          placeholder="请输入名称"
          v-model="listQueryMemory.name"
          style="width: 160px"
          class="filter-item"
          size="small"
          @keyup.enter="getMemoryList"
          clearable
        />
        <el-button type="primary" size="small" @click="getMemoryList">
          <el-icon><Search /></el-icon> <span> 搜索</span>
        </el-button>
      </div>
      <div class="right_box">
        <qzf-button
          button_code="yhzh_sc"
          plain
          size="small"
          type="danger"
          @success="delAllMemory"
        >
          <el-icon><Delete /></el-icon> <span>删除</span>
        </qzf-button>
      </div>
    </div>
    <el-table
      stripe
      :data="memoryList"
      v-loading="memoryLoading"
      :height="contentStyleObj"
      style="width: 100%"
      border
      @selection-change="handleSelectionChangeMemory"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column
        align="left"
        prop="corpName"
        label="销方名称"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="subjectName"
        label="对应科目"
        min-width="180"
      >
        <template #default="scope">
          <subject-list
            :width="350"
            v-model:subjectId="scope.row.subjectId"
            v-model:fzhsItemId="scope.row.fzhsID"
            :disabledIn="true"
          ></subject-list>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" width="100">
        <template #default="scope">
          <qzf-button
            button_code="yhzh_sc"
            size="small"
            @success="delOne(scope.row)"
            link
            >删除</qzf-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <qzf-pagination
        v-show="memoryTotal > 0"
        :total="memoryTotal"
        v-model:page="listQueryMemory.page"
        v-model:limit="listQueryMemory.limit"
        @pagination="getMemoryList"
      />
    </div>
  </el-drawer>
</template>

<script>
import {
  incomeMemoryList,
  delIncomeMemory,
  incomeCorpMemoryList,
  delIncomeCorpMemory,
} from "@/api/memory";

export default {
  name: "invoiceIn",
  components: {},
  data() {
    return {
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
      },
      total: 0,
      multipleSelection: [],
      bankId: 0,
      loading: false,
      listQueryMemory: {
        page: 1,
        limit: 20,
        name: "",
      },
      drawer: false,
      memoryList: [],
      memoryTotal: 0,
      sels: [],
      memoryLoading:false
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(190);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      incomeMemoryList(this.listQuery).then((res) => {
        this.loading = false;
        this.list = res.data.data.list ? res.data.data.list : [];
        this.total = res.data.data.total;
      });
    },
    del(row) {
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delIncomeMemory([row]).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$qzfMessage("请至少选择一条", 1);
        return;
      }
      this.$confirm("确定删除选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delIncomeMemory(this.multipleSelection).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    //删除销方记忆
    corpMemory() {
      this.drawer = true;
      this.getMemoryList();
    },
    handleSelectionChangeMemory(val) {
      this.sels = val;
    },
    getMemoryList() {
      this.memoryLoading = true
      incomeCorpMemoryList(this.listQueryMemory).then((res) => {
        this.memoryLoading = false
        if (res.data.msg == "success") {
          this.memoryList = res.data.data.list ? res.data.data.list : [];
          this.memoryTotal = res.data.data.total;
        }
      });
    },
    delOne(row) {
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delIncomeCorpMemory([row]).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getMemoryList();
          }
        });
      });
    },
    delAllMemory() {
      if (this.sels.length == 0) {
        this.$qzfMessage("请至少选择一条", 1);
        return;
      }
      this.$confirm("确定删除选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delIncomeCorpMemory(this.sels).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getMemoryList();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon {
  font-size: 13px;
  .iconfont {
    font-size: 13px;
    margin-right: 4px;
  }
}
</style>
