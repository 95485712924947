<template>
  <div class="app-container">
    <div class="top_btns">
      <div>
        <el-input
          placeholder="请输入账户名称"
          v-model="listQuery.name"
          style="width: 160px"
          class="filter-item"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button type="primary" size="small" @click="getList">
          <el-icon><Search /></el-icon> <span> 搜索</span>
        </el-button>
      </div>
      <div>
        <qzf-button
          button_code="yhzh_xz"
          size="small"
          type="primary"
          @success="add"
        >
          <el-icon><CirclePlus /></el-icon> <span>新增</span>
        </qzf-button>
        <qzf-button
          button_code="yhzh_sc"
          plain
          size="small"
          type="danger"
          @success="delAll"
        >
          <el-icon><Delete /></el-icon> <span>删除</span>
        </qzf-button>
      </div>
    </div>
    <el-table
      stripe
      :data="list"
      :height="contentStyleObj"
      style="width: 100%"
      border
      @selection-change="handleSelectionChange"
      v-loading="loading"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="账户名称"
        min-width="160"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="subjectName"
        label="对应科目"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="bankNo"
        label="银行主账号"
        min-width="180"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="xybh"
        label="协议编号"
        min-width="260"
      >
        <template #default="scope">
          <div class="item_icon">
            <span v-if="scope.row.xybh">
              <i class="iconfont icon-duihao"></i>已授权
              {{ scope.row.xybh }}
            </span>
            <span v-else><i class="iconfont icon-gantanhao"></i>未授权</span>
            <qzf-button
              style="position: relative; top: -1.5px"
              @success="showAgreement(scope.row)"
              size="small"
              type="text"
              :disabled="!$buttonStatus('ywsj_yhzh_qsq')"
              >去授权</qzf-button
            >
            <p v-if="scope.row.xyTime">授权时间：{{ scope.row.xyTime }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="currency"
        label="币种"
        min-width="90"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="address"
        label="操作"
        min-width="130"
      >
        <template #default="scope">
          <qzf-button
            button_code="ywsj_yhzh_bj"
            @success="handleUpdate(scope.row)"
            size="small"
            type="text"
            >编辑</qzf-button
          >
          <qzf-button
            button_code="yhzh_sc"
            size="small"
            @success="del(scope.row)"
            type="text"
            >删除</qzf-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <accreditBank
      ref="accreditBank"
      :bankName="bankName"
      :bankId="bankId"
      @success="getList"
    ></accreditBank>
    <bankAuth ref="bankAuth" @success="getList"></bankAuth>
  </div>
</template>

<script>
import {
  bankNameList,
  bankNameDel,
} from "@/api/bank";
import accreditBank from "../../batch/components/accreditBank.vue";
import bankAuth from "@/components/bankAuth";
export default {
  name: "bankAccount",
  components: {
    accreditBank,
    bankAuth,
  },
  data() {
    return {
      comId: this.$store.getters["user/comInfo"].comId * 1,
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
      },
      total: 0,
      multipleSelection: [],
      bankId: 0,
      loading:false
    };
  },
  created() {
    this.contentStyleObj = this.$getHeight(190);
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      bankNameList(this.listQuery).then((res) => {
        this.loading = false
        if (res.data.msg == "success") {
          this.list = res.data.data.list ? res.data.data.list : [];
          this.total = res.data.data.total;
        }
      });
    },
    add() {
      let param = {
        comId: this.comId,
        comName: "",
      };
      this.$refs.bankAuth.init(param);
    },
    handleUpdate(row) {
      let param = {
        comId: this.comId,
        comName: "",
      };
      this.$refs.bankAuth.init(param, row, "accountBook");
    },
    del(row) {
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        bankNameDel([row]).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("删除成功");
            this.getList();
          }
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$qzfMessage("请至少选择一条", 1);
        return;
      }
      this.$confirm("确定删除选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        bankNameDel(this.multipleSelection).then((res) => {
          this.$qzfMessage("删除成功");
          this.getList();
        });
      });
    },
    // 签订协议弹框
    showAgreement(row) {
      this.bankName = row.name;
      this.bankId = row.id;
      row.bankId = row.id;
      this.$refs.accreditBank.init(0, "", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item_icon {
  font-size: 13px;
  .iconfont {
    font-size: 13px;
    margin-right: 4px;
  }
}
</style>
