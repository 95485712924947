<template>
  <div class="app-container">
    <div class="top_btns">
      <div class="left_box">
        <el-input placeholder="请输入账户名称/对方户名" v-model="listQuery.name" style="width: 200px;" size="small" @keyup.enter="getList" clearable/>
        <el-button type="primary" size="small" @click="getList" icon="Search">搜索
        </el-button>
      </div>
      <div class="right_box">
        <qzf-button button_code="yhzh_sc" plain size="small" type="danger" @success="delAll"  >
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </qzf-button>
      </div>
    </div>
    <el-table stripe :data="list" v-loading="loading" :height="contentStyleObj" style="width: 100%" border @selection-change="handleSelectionChange">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column align="center" prop="name" label="银行账户" min-width="160" show-overflow-tooltip> 
        <template #default="scope">
          <el-select placeholder="请选择" v-model="scope.row.bankId" size="small" disabled>
            <el-option v-for="(item,index) in bankList " :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="name" label="对方户名" min-width="180">
      </el-table-column>
      <el-table-column align="center" prop="bankNo" label="业务类型" min-width="180"> 
        <template #default="scope">
          <business-type :disabledIn="true" :name="scope.row.otherName" v-model:businessId="scope.row.businessTypeId" v-model:itemId="scope.row.subjectId" v-model:fzhsId="scope.row.fzhsId" v-model:szbz="scope.row.type" type="bank"></business-type>
        </template>
      </el-table-column>
      <el-table-column align="left" prop="summary" label="摘要" min-width="90">
      </el-table-column>
      <el-table-column align="center" prop="address" label="操作" min-width="130">
        <template #default="scope">
          <qzf-button button_code="yhzh_sc" size="small" @success="del(scope.row)" link>删除</qzf-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { bankMemoryList ,delBankMemory} from "@/api/memory"
import { bankNameList  } from "@/api/bank"

export default {
  name:'bank',
  data() {
    return {
      list:[],
      listQuery:{
        page:1,
        limit:20
      },
      total:0,
      multipleSelection:[],
      bankList:[],
      bankId:0,
      loading:false,
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(190)
    this.init()
    this.getList()
  },
  methods:{
    init(){
      bankNameList({}).then(res1=>{
        this.bankList = res1.data.data.list
      })
    },
    getList(){
      this.loading = true
      bankMemoryList(this.listQuery).then(res=>{
        this.loading = false
        this.list = res.data.data.list ? res.data.data.list : []
        this.total = res.data.data.total
      })
    },
    del(row){
      this.$confirm("确定删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delBankMemory([row]).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("删除成功")
            this.getList()
          }
        })
      }); 
    },
    handleSelectionChange(val){
      this.multipleSelection = val;
    },
    delAll(){
      if(this.multipleSelection.length == 0){
        this.$qzfMessage("请至少选择一条", 1)
        return
      }
      this.$confirm("确定删除选项吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delBankMemory(this.multipleSelection).then(res=>{
          this.$qzfMessage("删除成功")
          this.getList();
        })
      }); 
    },
  }
}
</script>

<style lang="scss" scoped>
.top_btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:10px;
}
 .item_icon{
  font-size: 13px;
  .iconfont{
    font-size: 13px;
    margin-right: 4px;
  }
 }
</style>